<template>
  <section>
    <BackToolbar bgcolor="transparent" :routename="routeName" :customRoute="customRoute"></BackToolbar>
    <v-main>
      <v-container class="loginscreen">
        <v-container class="text-center">
          <v-form ref="form" lazy-validation>
            <v-row class="justify-content-center">
              <v-col cols="10" class="py-2">
                <v-alert
                  v-if="error && !forgetPasswordDialog"
                  type="error"
                  class="text-caption text-left"
                  transition="fade-transition"
                  dense
                  >{{ error }}</v-alert
                >
                <v-alert
                  v-if="success"
                  type="success"
                  class="text-caption text-left"
                  transition="fade-transition"
                  dense
                  >{{ success }}</v-alert
                >
                <v-text-field
                  @keydown.native.space.prevent
                  v-model="formData.email"
                  class="text-caption"
                  placeholder="Email Address"
                  :rules="emailRules"
                  hide-details
                  outlined
                  dense
                >
                  <v-icon slot="prepend-inner" class="mr-2">mdi-account-arrow-right-outline</v-icon>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="justify-content-center">
              <v-col cols="10" class="py-2">
                <v-text-field
                  type="password"
                  v-model="formData.password"
                  class="text-caption"
                  placeholder="Enter Password"
                  :rules="passwordRules"
                  hide-details
                  outlined
                  dense
                >
                  <v-icon slot="prepend-inner" class="mr-2">mdi-lock-outline</v-icon>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="justify-content-center" style="cursor: pointer" @click="promptForgetPasswordDialog()">
              <v-col cols="10" class="py-3 text-body-2 text-center font-weight-5 grey--text text--darken-1">
                <v-hover
                  v-slot="{ hover }"
                >
                  <small :class="{ 'blue--text text--lighten-1': hover }">Forgot Password?</small>
                </v-hover>
              </v-col>
            </v-row>
            <v-row class="justify-content-center">
              <v-col cols="10" class="py-2">
                <v-btn
                  color="primary"
                  class="text-caption font-weight-bold"
                  :disabled="isLogging"
                  block
                  @click="login()"
                >
                  <span v-if="isLogging">Loading..</span>
                  <span v-else>Login</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-row class="justify-content-center">
            <v-col cols="10" class="py-3">
              <div class="text-caption text-center font-weight-5 grey--text text--darken-1">
                Don't have an account? <span class="blue--text" @click="register()">Sign up</span>
              </div>
            </v-col>
          </v-row>
          <v-row class="justify-content-center">
            <v-col cols="10">
              <v-row class="justify-content-center align-items-center">
                <v-col cols="4">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="4" class="pa-0">
                  <p class="text-caption grey--text">
                    or Sign in with
                  </p>
                </v-col>
                <v-col cols="4">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="justify-content-center align-items-center" v-if="isBrowserSupported">
            <Fblogin
              v-on:success="redirect()"
            ></Fblogin>
            <Googlelogin
              v-if="isBrowserSupported && googleClientId"
              v-on:success="redirect()"
              :params="getGoogleParams()"
            ></Googlelogin>
          </v-row>
          <v-row class="justify-content-center align-items-center" v-else>
            <v-col cols="9" class="pa-0 ma-0">
              <p class="smallhelpertext red--text text--darken-1">
                Please tap <small><v-icon>mdi-dots-horizontal</v-icon></small> and choose <v-icon dense>mdi-web</v-icon> <small class="text-caption font-weight-6 grey--text text--darken-2">Open in browser</small> to sign in with Google / Facebook
              </p>
            </v-col>
          </v-row>

          <br />

          <v-row class="my-2" justify="center">
            <v-col cols="9" class="text-center">
              <p class="mb-6 text-caption grey--text text--lighten-1">
                By continuing, you have read & agreed to our privacy policy.
              </p></v-col
            ></v-row
          >
          <v-row class="justify-content-center" v-if="!isRequiredLogin && isCheckout">
            <v-col cols="10" class="py-2">
              <v-btn
                color="grey lighten-3"
                class="text-caption grey--text"
                block
                @click="guestCheckout()"
              >
                Continue as guest
              </v-btn>
            </v-col>
          </v-row>
        </v-container></v-container
      >
      <register-modal @close="showRegister = false" :visible="showRegister"></register-modal>
      <v-dialog
        v-model="forgetPasswordDialog"
        max-width="290"
      >
        <v-form ref="resetPasswordForm" lazy-validation>
          <v-card>
            <v-card-title class="text-h6">
              Reset Password
            </v-card-title>
            <v-card-text class="text-caption">
              <v-text-field
                @keydown.native.space.prevent
                v-model="resetPasswordEmail"
                class="text-caption"
                placeholder="Email Address"
                :rules="emailRules"
                hide-details
                outlined
                dense
              >
                <v-icon slot="prepend-inner" class="mr-2">mdi-account-arrow-right-outline</v-icon>
              </v-text-field>
              <div class="reset-password-hint mt-2 px-2 red--text text--lighten-3" v-if="!error">
                We will reset your password and send it to your account's email.
              </div>
              <div class="reset-password-hint mt-2 px-2 red--text text--lighten-1" v-if="error">
                {{ error }}
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="grey lighten-1"
                x-small
                text
                plain
                @click="forgetPasswordDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                x-small
                text
                plain
                @click="confirmResetPassword()"
              >
                Confirm
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
        <v-overlay :value="isLoading">
          <v-progress-circular
            indeterminate
            size="64"
          ></v-progress-circular>
        </v-overlay>
      </v-dialog>
    </v-main>
  </section>
</template>

<script>
import BackToolbar from '../../components/partials/BackToolbar';
import Fblogin from '@/components/Buttons/Fblogin';
import Googlelogin from '@/components/Buttons/Googlelogin';
import RegisterModal from '@/components/Modals/Register'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
  components: {
    BackToolbar,
    Fblogin,
    Googlelogin,
    RegisterModal,
  },
  props: ['routeto'],
  data: function() {
    return {
      isLogging: false,
      countryCode: process.env.VUE_APP_COUNTRY_CODE,
      routeName: 'menu',
      customRoute: '',
      error: null,
      success: null,
      formData: {
        email: null,
        password: null,
      },
      emailRules: [v => (!!v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) || 'Email address is required and must be valid'],
      passwordRules: [v => !!v || 'Password is required'],
      googleClientId: null,
      isLoading: false,
      showRegister: false,
      resetPasswordEmail: null,
      forgetPasswordDialog: false,
      resolve: null,
    };
  },
  computed: {
    ...mapState('auth', {
      authRedirect: (state) => state.authRedirect,
    }),
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    ...mapGetters('auth', {
      isLoggedIn: 'isLoggedIn',
    }),
    ...mapGetters('merchant', {
      isRequiredLogin: 'isRequiredLogin',
    }),
    isBrowserSupported: function() {
      return !(/(.browser|.fbav|.micromessenger(?=\/))\/?\s*(\d+)/i).test(navigator.userAgent) && (/(opera|chrome|safari|firefox|msie(?=\/))\/?\s*(\d+)/i).test(navigator.userAgent);
    },
    isCheckout: function() {
      return this.$route.query?.redirectFullPath?.includes("checkout");
    },
  },
  methods: {
    ...mapActions('config', ['getAdminOption']),
    ...mapActions('auth', ['authenticate', 'resetPassword']),
    ...mapMutations('auth', ['SET_REDIRECT']),
    getGoogleParams() {
      return {
        client_id: this.googleClientId
      };
    },
    register() {
      this.$gtag.event('view_sign_up', {
        'event_category': 'navigation',
        'event_label': 'Register',
      });
      
      this.showRegister = true;
    },
    async promptForgetPasswordDialog() {
      this.$gtag.event('prompt_forget_password', {
        'event_category': 'navigation',
        'event_label': 'Forget Password',
      });
      
      this.forgetPasswordDialog = true;
    },
    async confirmResetPassword() {
      if (this.$refs.resetPasswordForm.validate()) {
        this.isLoading = true;
        const res = await this.resetPassword({
          email: this.resetPasswordEmail,
        });

        switch (res.status) {
          case 422:
            this.error = res.data.message;
            break;
          case 404:
            this.error = 'Email address not found';
            break;
          case 400:
            this.error = 'Sorry, system is busy. Please try again later.';
            break;
          default:
            this.success = 'Your password is reset. Please check your email for the new password.';
            break;
        }

        this.isLoading = false;

        if (this.error) {
          this.$gtag.event('reset-password', {
            'event_category': 'experience',
            'event_label': 'Reset Password (error)',
            'event_value': this.error,
          });
          setTimeout(() => this.error = null, 5000);
        } else if (this.success) {
          setTimeout(() => this.success = null, 8000);
          this.resetPasswordEmail = null;
          this.forgetPasswordDialog = false;
        }
      }
    },
    async login() {
      this.isLogging = true;
      if (this.$refs.form.validate()) {
        try {
          this.$gtag.event('login', {
            'event_category': 'engagement',
            'event_label': 'Login',
            'event_value': 'web',
          });

          await this.authenticate({
            email: this.formData.email,
            password: this.formData.password,
            method: 'web',
            merchantId: this.profile.merchant_id,
          });

          this.redirect();
        } catch (e) {
          this.error = 'Invalid email address or password';

          setTimeout(() => (this.error = null), 5000);
        }
      }
      this.isLogging = false;
    },
    async guestCheckout() {
      this.$cookies.set('isGuest', true);
      this.$router.push('checkout').catch(() => {});
    },
    redirect() {
      this.SET_REDIRECT(null);

      if (this.$route.query.redirect) {
        this.$router.push({ name: this.$route.query.redirect });
      } else if (this.$route.query.redirectFullPath) {
        this.$router.push({ path: this.$route.query.redirectFullPath });
      } else {
        this.$router.push({ name: 'home' });
      }
    },
  },
  async mounted() {
    if (this.isLoggedIn) {
      const routePath = this.authRedirect || this.$route.query?.redirectFullPath || 'home';
      this.SET_REDIRECT(null);
      this.$router.push({ path: routePath });
    }

    if (this.$route.query?.customRoute) {
      this.routeName = '';
      this.customRoute = this.$route.query?.customRoute;
    }

    this.SET_REDIRECT(this.$route.query?.redirectFullPath);
    this.googleClientId = await this.getAdminOption({ key: 'google' });
  },
};
</script>

<style scoped>
@media screen and (max-width: 375px) {
  .loginscreen h5 {
    font-size: 16px;
  }
  .loginscreen .smallhelpertext {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 360px) {
  .loginscreen .medhelpertext {
    font-size: 12px;
  }
  .loginscreen .smallhelpertext {
    font-size: 11px !important;
  }
}
@media screen and (max-width: 320px) {
  .loginscreen .smallhelpertext {
    font-size: 9px !important;
  }
  .loginscreen h5 {
    font-size: 16px;
  }
  .loginscreen .v-text-field__slot {
    font-size: 13px;
  }
}
.loginscreen .smallhelpertext {
  font-size: 13px;
}
.mobilenmberfield input[placeholder='Mobile Number'] {
  padding-left: 10px;
}
.endbtn .v-btn__content {
  color: white !important;
  font-size: 18px;
}
.reset-password-hint {
  font-size: 10px;
  font-weight: 600;
}
</style>
